import { useRef, useState } from "react";

import styled from "@emotion/styled";
import { Card as MaterialCard, Popover, TextField } from "@mui/material";
import type { AddUserToListInput, Platform_Enum } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import Papa from "papaparse";

import { useApolloClient } from "@apollo/client";
import { AddCircle } from "@mui/icons-material";
import { objectValues } from "@relatable/helpers/objects";
import { DropZone, type Status } from "@relatable/ui/DropZone";
import {
  CreatorsInListDocument,
  SearchListsDocument,
  useAddUserToListMutation
} from "../generated";

const Root = styled.div`
  display: inline-block;

  > button {
    border: 0;
    background: none;
    color: ${palette.gray[60]};
    cursor: pointer;
  }
`;

const Card = styled(MaterialCard)`
  padding: 15px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

export const ManualAdd: React.FC<{ listId: number; platform: Platform_Enum }> = ({
  listId,
  platform
}) => {
  const snackbar = useSnackbar();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState("");

  const [statuses, setStatuses] = useState<Record<string, { name: string; status: Status }>>({});

  const apolloClient = useApolloClient();
  const [addUserToList, { loading: loadingAdd }] = useAddUserToListMutation({
    onCompleted: () => snackbar.success("User was added to the list"),
    onError: error => snackbar.error(error.message)
  });

  const handleAdd = async (username: string) => {
    const trimmedUsername = username.trim();
    if (!trimmedUsername) return;
    if (trimmedUsername.startsWith("@")) {
      snackbar.error("@ character shouldn't be included here");
      return;
    }

    const selector: Partial<AddUserToListInput> = (() => {
      if (platform === "instagram") {
        return { ig_username: trimmedUsername };
      }
      if (platform === "tiktok") {
        return { tt_username: trimmedUsername };
      }
      if (platform === "snapchat") {
        return { sc_username: trimmedUsername };
      }
      if (platform === "youtube") {
        if (trimmedUsername.startsWith("UC")) {
          return { yt_channel_id: trimmedUsername };
        }
        return { yt_username: trimmedUsername };
      }
      throw new Error("Unsupported platform");
    })();

    await addUserToList({
      variables: {
        list_id: listId,
        ...selector
      }
    });
    setUsername("");
  };

  const handleClose = () => {
    apolloClient.refetchQueries({
      include: [CreatorsInListDocument, SearchListsDocument]
    });
    setOpen(false);
  };

  return (
    <Root ref={rootRef}>
      <button
        style={{ display: "flex", alignItems: "center", gap: 5 }}
        type="button"
        onClick={() => setOpen(true)}
      >
        <AddCircle />
        Add manually
      </button>

      <Popover
        open={open && Boolean(listId)}
        anchorEl={rootRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Card>
          <TextField
            size="small"
            label={
              platform === "youtube" ? "Profile's Channel ID or @ handle" : "Profile's username"
            }
            placeholder={platform === "youtube" ? "Channel ID or @ handle" : "username"}
            value={username}
            onChange={e => setUsername(e.target.value)}
            inputProps={{ style: { height: 40 } }}
          />
          <Button
            icon={<AddCircle />}
            isLoading={loadingAdd}
            onClick={async () => {
              await handleAdd(username);
              handleClose();
            }}
            style={{ marginTop: 15 }}
          >
            Add
          </Button>
        </Card>

        <Card>
          <DropZone
            label="Add csv of users"
            accept={{ "text/csv": [".csv"] }}
            statuses={objectValues(statuses)}
            onDrop={async files => {
              const usernames: string[] = [];
              for (const file of files) {
                const getUsernames = () =>
                  new Promise<string[]>(resolve => {
                    Papa.parse(file, {
                      skipEmptyLines: true,
                      header: true,
                      complete({ data: unknownData }) {
                        const data: { username?: string; Username?: string }[] = unknownData as any;
                        if (!data[0].username && !data[0].Username) {
                          snackbar.error(
                            "No username column found. Please add 'username' as the first row/column"
                          );
                          return;
                        }

                        resolve(data.map(row => row.username || row.Username || ""));
                      }
                    });
                  });
                usernames.push(...(await getUsernames()));
              }

              setStatuses(
                usernames.reduce((acc, username) => {
                  acc[username] = { name: username, status: "pending" };
                  return acc;
                }, statuses)
              );

              for (const username of usernames) {
                try {
                  await handleAdd(username);
                  setStatuses(prev => ({
                    ...prev,
                    [username]: { name: username, status: "success" }
                  }));
                } catch (error) {
                  setStatuses(prev => ({
                    ...prev,
                    [username]: { name: username, status: "error" }
                  }));
                }
              }

              handleClose();
            }}
          />
        </Card>
      </Popover>
    </Root>
  );
};
