import { type FC, useRef, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { Paper } from "@mui/material";
import { prettifyNumber } from "@relatable/helpers";
import { BUDGET_CATEGORIES } from "@relatable/helpers/constants";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { useParams } from "react-router-dom";

import { type BreadcrumbItem, Breadcrumbs } from "@relatable/ui/Breadcrumbs";
import { Title } from "lib/styled";

import { Footer } from "../Footer";
import { type AgreementFields, AgreementSection } from "./AgreementSection";
import { type ContentFields, ContentSection } from "./ContentSection";
import { type MainFields, MainSection } from "./MainSection";
import { type PayoutOfferFields, PayoutOffersSection } from "./PayoutOffersSection";
import { TrackingSection } from "./TrackingSection";
import {
  CampaignDetailsDocument,
  useCampaignDetailsQuery,
  useUpdateCampaignMutation
} from "./generated";
import type { SectionRef } from "./types";

const getInfluencerMarketingBudget = (
  project_budgets: { target_margin: number; revenue_allocation: number; type: string }[]
): { revenue_allocation: number; target_margin: number; payout_budget: number } =>
  project_budgets
    .filter(pb =>
      (
        [
          BUDGET_CATEGORIES.INFLUENCER_MARKETING_IG.value,
          BUDGET_CATEGORIES.INFLUENCER_MARKETING_YT.value,
          BUDGET_CATEGORIES.INFLUENCER_MARKETING_TT.value,
          BUDGET_CATEGORIES.LIVE_SHOPPING.value
        ] as string[]
      ).includes(pb.type)
    )
    .reduce(
      ({ revenue_allocation, payout_budget }, pb, index, srcArray) => {
        const targetMarginCasted = Number(pb.target_margin);
        const budgetRevenueAllocationCasted = Number(pb.revenue_allocation);

        const newPayoutBudget =
          payout_budget +
          (budgetRevenueAllocationCasted || budgetRevenueAllocationCasted === 0
            ? Math.round(budgetRevenueAllocationCasted * (1 - targetMarginCasted))
            : 0);
        const newRevenueAllocation = revenue_allocation + budgetRevenueAllocationCasted;

        return {
          revenue_allocation: newRevenueAllocation,
          payout_budget: newPayoutBudget,
          target_margin:
            index === srcArray.length - 1 ? 1 - newPayoutBudget / newRevenueAllocation : 0
        };
      },
      { revenue_allocation: 0, target_margin: 0, payout_budget: 0 }
    );

export const CampaignUpdate: FC = () => {
  useDocumentTitle("Edit campaign");
  const { campaignStub } = useParams<{ campaignStub: string }>();

  const snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const apolloClient = useApolloClient();

  const mainSectionRef = useRef<SectionRef<MainFields>>(null);
  const payoutSectionRef = useRef<SectionRef<PayoutOfferFields>>(null);
  const agreementSectionRef = useRef<SectionRef<AgreementFields>>(null);
  const contentSectionRef = useRef<SectionRef<ContentFields>>(null);

  const refs = [mainSectionRef, payoutSectionRef, agreementSectionRef, contentSectionRef];
  const [updateCampaign] = useUpdateCampaignMutation();

  const { data } = useCampaignDetailsQuery({
    variables: { stub: campaignStub ?? "" }
  });
  const campaign = data?.campaigns[0];

  const handleSubmit = async () => {
    if (isLoading || !campaign?.id) return;
    const errors = refs.flatMap(ref => ref.current?.validate() || []);
    if (errors.length) {
      snackbar.error(`Found ${errors.length === 1 ? "1 error" : `${errors.length} errors`}`);
      return;
    }

    try {
      setIsLoading(true);
      await Promise.all(refs.map(ref => ref.current?.submit()));
      await updateCampaign({
        variables: { id: campaign?.id, set: { updated_at: new Date().toISOString() } }
      });
      await apolloClient.refetchQueries({ include: [CampaignDetailsDocument] });
      snackbar.success("Campaign successfully saved");
    } catch (e) {
      await apolloClient.refetchQueries({ include: [CampaignDetailsDocument] });
      snackbar.error(e.message);
    }
    setIsLoading(false);
  };

  const { revenue_allocation, target_margin } = getInfluencerMarketingBudget(
    campaign?.project?.im_project_budgets || []
  );
  const project = campaign?.project;
  const getBreadcrumbsItems = () => {
    const breadcrumbsItems: BreadcrumbItem[] = [
      {
        title: "Home",
        path: "/overview"
      }
    ];

    if (project) {
      project.hubspot_deals.forEach(deal => {
        breadcrumbsItems.push({
          title: deal.client?.name || "",
          path: "/overview",
          search: `client=${deal?.client.hubspot_company_id}`,
          linkState: {
            from: "breadcrumbs"
          }
        });
      });

      breadcrumbsItems.push({
        title: project.name || "",
        path: `/project/${project.stub}/edit`,
        isReactLink: false,
        linkState: {
          from: "breadcrumbs"
        }
      });

      breadcrumbsItems.push({
        title: campaign.title || "",
        isReactLink: false,
        path: `/project/${project.stub}/campaign/${campaignStub}/edit`,
        dropdownItems: project.campaigns.map(c => ({
          title: c.title || "",
          path: `/project/${project.stub}/campaign/${c.stub}/edit`
        }))
      });
    }

    return breadcrumbsItems;
  };

  return (
    <>
      <Breadcrumbs style={{ marginLeft: "50px" }} defaultExpanded items={getBreadcrumbsItems()} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "calc(70% - 20px)"
        }}
      >
        <Title>Edit campaign</Title>
        <Paper
          variant="outlined"
          style={{
            background: "white",
            padding: "10px 20px",
            borderRadius: 10,
            display: "flex",
            gap: 20
          }}
        >
          {[
            ["Platform", campaign?.platform],
            ["Payout budget", `${campaign?.max_budget ?? ""} ${campaign?.currency ?? ""}`],
            [
              "Budget",
              prettifyNumber(
                (campaign?.max_budget || 0) / (revenue_allocation * (1 - target_margin)),
                { percentages: true }
              )
            ],
            ["CPM", campaign?.cpm_payout],
            ["Reach", campaign?.target_reach],
            [
              "Participants",
              `${campaign?.target_participants ?? ""} - ${campaign?.max_participants ?? ""}`
            ]
          ].map(i => {
            const [label, content] = i;
            return (
              <div key={label}>
                <div style={{ fontSize: 12 }}>{label}</div>
                <div style={{ fontWeight: 600 }}>{content || "…"}</div>
              </div>
            );
          })}
        </Paper>
      </div>

      <MainSection ref={mainSectionRef} campaignStub={campaignStub ?? ""} />
      <AgreementSection ref={agreementSectionRef} campaignStub={campaignStub ?? ""} />
      <PayoutOffersSection ref={payoutSectionRef} campaignStub={campaignStub ?? ""} />
      <ContentSection ref={contentSectionRef} campaignStub={campaignStub ?? ""} />
      <TrackingSection campaignStub={campaignStub ?? ""} />

      <div style={{ width: "100%", height: 100 }} />
      <Footer
        isArchived={Boolean(campaign?.archived)}
        lastUpdatedAt={campaign?.updated_at}
        backHref={project?.stub && `/project/${project?.stub}/edit`}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </>
  );
};
