import type { FC } from "react";

import styled from "@emotion/styled";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { Outlet, useParams } from "react-router-dom";

import { type BreadcrumbItem, Breadcrumbs } from "@relatable/ui/Breadcrumbs";
import { Loader } from "@relatable/ui/Loader";

import { useCampaignsQuery } from "./generated";

export const Campaign: FC = () => {
  const { campaignStub: rawCampaignStubs } = useParams<{
    campaignStub?: string;
  }>();
  const { loading: campaignLoading, data } = useCampaignsQuery({
    variables: { where: { stub: { _eq: rawCampaignStubs } } },
    skip: !rawCampaignStubs || rawCampaignStubs?.includes(",")
  });
  const campaign = data?.campaigns?.[0];

  useDocumentTitle(campaign ? campaign.title : "Campaign");

  if (campaignLoading) return <Loader />;

  const getBreadcrumbsItems = () => {
    const breadcrumbsItems: BreadcrumbItem[] = [
      {
        title: "Home",
        path: "/overview"
      }
    ];

    if (campaign) {
      campaign.project.hubspot_deals.forEach(deal => {
        breadcrumbsItems.push({
          title: deal.client.name || "",
          path: "/overview",
          search: `client=${deal.client.hubspot_company_id}`,
          linkState: {
            from: "breadcrumbs"
          }
        });
      });

      breadcrumbsItems.push({
        title: campaign?.project?.name || "",
        path: "/overview",
        search: `project=${campaign?.project?.stub}`,
        linkState: {
          from: "breadcrumbs"
        }
      });

      breadcrumbsItems.push({
        title: campaign.title || "",
        isReactLink: false,
        path: `/campaign/${campaign.stub}/participants`,
        dropdownItems: campaign?.project?.campaigns.map(c => ({
          title: c.title || "",
          path: `/campaign/${c.stub}/participants`
        }))
      });
    }

    return breadcrumbsItems;
  };

  if (data?.campaigns && data.campaigns.length === 0) {
    return <Section>Campaign not found!</Section>;
  }

  return (
    <>
      <Breadcrumbs items={getBreadcrumbsItems()} />

      <Section>
        <Outlet />
      </Section>
    </>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 50px;
`;
