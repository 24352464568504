import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { truncateFileName } from "@relatable/helpers/format";
import { palette } from "@relatable/ui/Palette";
import type { FC } from "react";
import { type Accept, useDropzone } from "react-dropzone";

const SectionSubtitle = styled.span`
font-size: 12px;
color: ${palette.gray[50]};
`;

import {
  AudioFile,
  Calculate,
  Description,
  FolderZip,
  InsertDriveFile,
  InsertPhoto,
  PictureAsPdf,
  Upload,
  VideoFile
} from "@mui/icons-material";

export const getFileIcon = (name: string) => {
  if (!name) return InsertDriveFile;
  const charList = name.split(".");
  const extension = charList[charList.length - 1];
  switch (extension) {
    case "pdf":
      return PictureAsPdf;
    case "mp3":
      return AudioFile;
    case "mp4":
    case "mov":
      return VideoFile;
    case "png":
    case "jpg":
    case "jpeg":
      return InsertPhoto;
    case "zip":
      return FolderZip;
    case "docx":
    case "txt":
      return Description;
    case "xlsx":
    case "xlsxm":
    case "xlsb":
    case "xltx":
    case "csv":
      return Calculate;

    default:
      return InsertDriveFile;
  }
};

export type Status = "pending" | "success" | "error";

export const DropZone: FC<{
  label: string;
  accept?: Accept;
  statuses: {
    name: string;
    status: Status;
  }[];
  onDrop: (files: File[]) => Promise<void>;
}> = ({ statuses, onDrop, accept, label }) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SectionSubtitle>{label}</SectionSubtitle>
        <SectionSubtitle>
          {statuses.filter(i => i.status === "success").length} / {statuses.length}
        </SectionSubtitle>
      </div>

      {statuses.length ? (
        <div
          style={{
            borderColor: palette.gray[50],
            color: palette.gray[50],
            borderRadius: 15,
            height: 200,
            width: 300,
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            gap: 10,
            overflow: "auto"
          }}
        >
          {statuses.map((value, index) => {
            const Icon = getFileIcon(value.name);
            return (
              <div
                style={{
                  padding: "10px 20px",
                  borderRadius: 10,
                  background:
                    value.status === "pending"
                      ? palette.tertiary.blue
                      : value.status === "success"
                        ? palette.tertiary.green
                        : palette.tertiary.red,
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  color: "black"
                }}
                key={index}
              >
                <Icon style={{ marginRight: 10 }} />
                {truncateFileName(value.name, 20)}
                <span style={{ marginLeft: "auto", color: palette.gray[50] }}>
                  {value.status === "pending" ? (
                    <CircularProgress style={{ marginLeft: 5 }} color="secondary" size={10} />
                  ) : (
                    value.status
                  )}
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            style={{
              border: "1px dashed",
              borderColor: palette.gray[50],
              color: palette.gray[50],
              borderRadius: 15,
              height: 200,
              width: 300,
              marginTop: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            <Upload style={{ marginRight: 10 }} />
            Drop here or click to upload
          </div>
        </div>
      )}
    </>
  );
};
