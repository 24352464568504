import { Stack } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { type FC, useState } from "react";

import { Popup } from "components/ui/Popup";
import { useUpdateCampaignUserMutation } from "modules/campaign/generated";

import { CampaignParticipantsDocument, CampaignParticipantsStatsDocument } from "../generated";

export const PaymentCell: FC<{
  localPay: number;
  campaignUserId: number;
  currency: string;
  exchangeRate: number;
  followers: number;
  postsCount: number;
}> = props => {
  const snackbar = useSnackbar();
  const [userState, setUserState] = useState<{ cpm: number } | { localPay: number }>({
    localPay: props.localPay
  });

  const priceFactor = props.postsCount * props.followers;

  const localPay =
    "localPay" in userState
      ? userState.localPay
      : Math.round(Math.round((priceFactor * userState.cpm) / 1000) * props.exchangeRate);

  const cpm =
    "cpm" in userState
      ? userState.cpm
      : Math.round((Math.round(userState.localPay / props.exchangeRate) * 1000) / priceFactor);

  const [isPopup, setIsPopup] = useState(false);

  const [updateCampaignUser] = useUpdateCampaignUserMutation({
    onCompleted: () => {
      setIsPopup(false);
      snackbar.success("User has been successfully updated");
    },
    onError: () => {
      snackbar.error("Something went wrong");
    },
    awaitRefetchQueries: true,
    refetchQueries: [CampaignParticipantsDocument, CampaignParticipantsStatsDocument]
  });

  return (
    <>
      <Button variant="text" style={{ cursor: "pointer" }} onClick={() => setIsPopup(true)}>
        <span style={{ width: "100%", textAlign: "left", textDecoration: "underline" }}>
          {localPay} {props.currency}
        </span>
      </Button>
      {isPopup && (
        <Popup
          onAction={() =>
            updateCampaignUser({
              variables: {
                campaignUserId: props.campaignUserId,
                _set: {
                  local_invoice_pay: localPay
                }
              }
            })
          }
          actionLabel="Save"
          onClose={() => setIsPopup(false)}
          title={`Payment in ${props.currency}`}
        >
          <Stack spacing={3}>
            <TextInput
              value={String(localPay)}
              onChange={v => setUserState({ localPay: Number(v) })}
              label={`Local invoice pay [${props.currency}]`}
              type="number"
            />
            <TextInput
              value={String(Math.round(localPay / props.exchangeRate))}
              type="number"
              label="Global pay [SEK]"
              disabled
              onChange={() => null}
            />
            <TextInput
              value={String(cpm)}
              type="number"
              label="CPM"
              onChange={v => setUserState({ cpm: Number(v) })}
            />
            {props.exchangeRate !== 1 && (
              <TextInput
                value={String(props.exchangeRate)}
                type="number"
                label={`Exchange rate [${props.currency}] `}
                disabled
                onChange={() => null}
              />
            )}
          </Stack>
        </Popup>
      )}
    </>
  );
};
