import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

import { TagEditor } from "components/ui/TagEditor";
import { useUpdateUserMutation } from "modules/user/generated";
import { useUser } from "src/hooks/useUser";

import { CommentEditor } from "./CommentEditor";
import { CampaignUserReviewDocument } from "./generated";

const Root = styled.div`
  display: flex;
  gap: 2%;
  margin-top: 26px;

  > .left,
  > .right {
    width: 49%;
    border: 1px solid ${palette.gray[10]};
    border-radius: 10px;
  }
`;

export const UserStep: FC<{
  tagIds: number[];
  comment: string;
  userId: number;
}> = ({ tagIds, userId, comment }) => {
  const [localTagIds, setLocalTagIds] = useState(tagIds);

  const admin = useUser();
  const [updateUser] = useUpdateUserMutation({
    refetchQueries: [CampaignUserReviewDocument]
  });

  const handleRelatableCommentChange = async (relatable_comment: string) => {
    await updateUser({
      variables: {
        id: userId,
        _set: {
          relatable_comment,
          relatable_comment_updated_at: new Date().toISOString(),
          relatable_comment_account_id: admin?.id || undefined
        }
      }
    });
  };

  const handleTagAdd = async (id: number) => {
    const newTagIds = Array.from(new Set([...localTagIds, id]));
    setLocalTagIds(newTagIds);

    await updateUser({
      variables: {
        id: userId,
        _set: {
          tag_ids: newTagIds
        }
      }
    });
  };

  const handleTagDelete = async (id: number) => {
    const newTagIds = localTagIds.filter(p => p !== id);
    setLocalTagIds(newTagIds);

    await updateUser({
      variables: {
        id: userId,
        _set: {
          tag_ids: newTagIds
        }
      }
    });
  };

  return (
    <Root>
      <div className="left">
        <TagEditor tagIds={localTagIds} onAdd={handleTagAdd} onDelete={handleTagDelete} />
      </div>
      <div className="right">
        <CommentEditor value={comment} onChange={handleRelatableCommentChange} />
      </div>
    </Root>
  );
};
