import { type FC, useCallback, useState } from "react";

import { useApolloClient } from "@apollo/client";
import styled from "@emotion/styled";

import { DropZone, type Status } from "@relatable/ui/DropZone";
import { palette } from "@relatable/ui/Palette";
import { useOnOutsideClick } from "@relatable/ui/hooks/useOnOutsideClick";

import { useSignUploadUrlMutation } from "modules/generated";

import {
  AddCircle,
  AudioFile,
  Calculate,
  Description,
  FolderZip,
  InsertDriveFile,
  InsertPhoto,
  Link,
  PictureAsPdf,
  VideoFile
} from "@mui/icons-material";
import { objectValues } from "@relatable/helpers/objects";
import {
  UserDocument,
  useDeleteUserAttachmentMutation,
  useInsertUserAttachmentMutation
} from "../generated";
import { Section, SectionSubtitle } from "../user.styled";
import { EditSectionIcon } from "./EditSectionIcon";
import { DeleteButton, FloatingContainer, ScrollableSectionContent } from "./styled";
import { useOptimisticUserUpdate } from "./useOptimisticUserUpdate";

const getFileIcon = (name: string) => {
  if (!name) return InsertDriveFile;
  const charList = name.split(".");
  const extension = charList[charList.length - 1];
  switch (extension) {
    case "pdf":
      return PictureAsPdf;
    case "mp3":
      return AudioFile;
    case "mp4":
    case "mov":
      return VideoFile;
    case "png":
    case "jpg":
    case "jpeg":
      return InsertPhoto;
    case "zip":
      return FolderZip;
    case "docx":
    case "txt":
      return Description;
    case "xlsx":
    case "xlsxm":
    case "xlsb":
    case "xltx":
    case "csv":
      return Calculate;

    default:
      return InsertDriveFile;
  }
};

const SAttachment = styled.button`
  background: white;
  padding: 5px 10px;
  color: ${palette.gray[50]};
  border-radius: 10px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  transition: background 250ms ease;
  :hover {
    background: ${palette.gray[30]};
  }
`;

const downloadFile = ({ url, fileName }: { url: string; fileName: string }) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.target = "_blank";
  a.rel = "noreferrer";
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const AttachmentsSection: FC<{
  attachments: { id: number; name: string; url: string }[];
  userName: string;
  userId: number;
}> = ({ attachments, userName, userId }) => {
  const apolloClient = useApolloClient();
  const optimisticUserUpdate = useOptimisticUserUpdate(userId);

  const [isAddPopup, setIsAddPopup] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [signUploadUrlMutation] = useSignUploadUrlMutation();
  const [insertAttachment] = useInsertUserAttachmentMutation();
  const [deleteAttachment] = useDeleteUserAttachmentMutation({
    onError: () => apolloClient.refetchQueries({ include: [UserDocument] })
  });

  const handleDeleteAttachment = (id: number) => {
    deleteAttachment({ variables: { id } });
    optimisticUserUpdate(prev => ({
      user_attachments: prev.user_attachments.filter(attachment => attachment.id !== id)
    }));
  };

  const [statuses, setStatuses] = useState<Record<string, { name: string; status: Status }>>({});
  const statusesValues = objectValues(statuses);
  const isUploading = statusesValues.some(i => i.status === "pending");

  const sectionRef = useOnOutsideClick(() => {
    if (!isUploading) {
      setIsAddPopup(false);
      setIsEditing(false);
      setStatuses({});
    }
  });

  const handleDrop = useCallback(
    async (files: File[]) => {
      setStatuses(
        files.reduce(
          (acc, i, index) => ({ ...acc, [index]: { status: "pending", name: i.name } }),
          {}
        )
      );

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          const { data } = await signUploadUrlMutation({
            variables: {
              input: {
                fileName: `${userName}/${file.name}`,
                fileType: file.type,
                prefix: "USER_ATTACHMENT"
              }
            }
          });
          if (!data?.signUploadUrl) {
            throw new Error("Something went wrong when signing a file for the upload");
          }

          const { url, signedUploadUrl } = data.signUploadUrl;
          const response = await fetch(signedUploadUrl, {
            method: "PUT",
            body: file,
            headers: { "content-type": file?.type }
          });

          if (!response.ok) {
            throw new Error("Something went wrong when uploading a file");
          }

          await insertAttachment({
            variables: {
              object: {
                original_filename: file.name,
                stored_filename: url,
                size: String(file.size),
                user_id: userId
              }
            }
          });
          setStatuses(p => ({ ...p, [i]: { ...p[i], status: "success" } }));
        } catch (err) {
          console.error(err);
          setStatuses(p => ({ ...p, [i]: { ...p[i], status: "error" } }));
        }
      }

      apolloClient.refetchQueries({ include: [UserDocument] });
    },
    [apolloClient, signUploadUrlMutation, userName, insertAttachment, userId]
  );

  return (
    <Section style={{ background: "transparent", boxShadow: "none" }} ref={sectionRef}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link style={{ color: palette.gray[50] }} />
        <SectionSubtitle style={{ color: "black", marginLeft: 10 }}>Attachments</SectionSubtitle>
        <div style={{ marginLeft: "auto", position: "relative" }}>
          <AddCircle
            style={{ color: palette.gray[50], cursor: "pointer", marginRight: 5 }}
            onClick={() => setIsAddPopup(p => !p)}
          />
          <EditSectionIcon isEditing={isEditing} onToggle={() => setIsEditing(p => !p)} />

          {isAddPopup && (
            <FloatingContainer>
              <DropZone label="Add attachments" onDrop={handleDrop} statuses={statusesValues} />
            </FloatingContainer>
          )}
        </div>
      </div>

      <ScrollableSectionContent>
        {!attachments.length && (
          <p style={{ fontSize: 12, color: palette.gray[50] }}>
            No attachments assigned to this user.
          </p>
        )}
        {attachments.map(t => {
          const Icon = getFileIcon(t.name);
          return (
            <div key={t.id} style={{ position: "relative" }}>
              {isEditing && (
                <DeleteButton type="button" onClick={() => handleDeleteAttachment(t.id)}>
                  <div
                    style={{
                      width: 10,
                      height: 2,
                      background: "white",
                      position: "relative",
                      left: -3
                    }}
                  />
                </DeleteButton>
              )}
              <SAttachment
                type="button"
                key={t.id}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 10 }}
                onClick={() => {
                  downloadFile({ fileName: t.name, url: t.url });
                }}
              >
                <Icon style={{ color: palette.primary.blue }} fontSize={"small"} />
                {t.name}
              </SAttachment>
            </div>
          );
        })}
      </ScrollableSectionContent>
    </Section>
  );
};
