import { prettifyNumber, truncateText } from "@relatable/helpers";

import type { UserQuery } from "../generated";

type FieldType = {
  header: string;
  value: string | JSX.Element | JSX.Element[];
  onChange?: (v: string) => void;
};

const getProfileFields = ({
  user
}: {
  user: NonNullable<UserQuery["users_by_pk"]>;
}): FieldType[] => {
  if (!user.user_profile) return [];

  const fields: FieldType[] = [];

  if (user.user_profile.phone_number) {
    fields.push({
      header: "Phone",
      value: user.user_profile.phone_number
    });
  }

  if (user.user_profile.address_city) {
    fields.push({
      header: "City",
      value: user.user_profile.address_city
    });
  }

  if (user.user_profile.address_state) {
    fields.push({
      header: "State",
      value: user.user_profile.address_state
    });
  }

  if (user.user_profile.address_meta) {
    fields.push({
      header: "Address",
      value: user.user_profile.address_meta
    });
  }

  if (user.user_profile.address_post_code) {
    fields.push({
      header: "Postcode",
      value: user.user_profile.address_post_code
    });
  }

  return fields;
};

const getGenericFields = ({
  user
}: {
  user: NonNullable<UserQuery["users_by_pk"]>;
}): FieldType[] => {
  const fields: FieldType[] = [
    {
      header: "Gigapay status",
      value: user?.gigapay_id ? "Linked" : "-"
    }
  ];

  const avgEngagement =
    user.user_instagram?.avg_engagement ??
    user.user_tiktok?.avg_engagement ??
    user.user_youtube?.avg_engagement;

  if (avgEngagement) {
    fields.push({
      header: "Avg engagement",
      value: prettifyNumber(avgEngagement, { percentages: true })
    });
  }

  const website = user.user_instagram?.website ?? user.user_tiktok?.website;
  if (website) {
    fields.push({
      header: "Website",
      value: (
        <a href={website} target="_blank" rel="noreferrer">
          {truncateText(website, 40)}
        </a>
      )
    });
  }

  return fields;
};

const getInstagramFields = ({
  user
}: {
  user: NonNullable<UserQuery["users_by_pk"]>;
}): FieldType[] => {
  if (!user.user_instagram) return [];

  const fields: FieldType[] = [];

  if (user.user_instagram.tags) {
    fields.push({ header: "Tags", value: String(user.user_instagram.tags) });
  }

  if (user.user_instagram.media_count) {
    fields.push({
      header: "Media count",
      value: prettifyNumber(user.user_instagram.media_count)
    });
  }

  return fields;
};

const getTikTokFields = ({
  user
}: {
  user: NonNullable<UserQuery["users_by_pk"]>;
}): FieldType[] => {
  if (!user.user_tiktok) return [];

  const fields: FieldType[] = [];

  if (user.user_tiktok.likes) {
    fields.push({
      header: "Likes",
      value: prettifyNumber(user.user_tiktok.likes)
    });
  }

  if (user.user_tiktok.comments) {
    fields.push({
      header: "Comments",
      value: prettifyNumber(user.user_tiktok.comments)
    });
  }

  if (user.user_tiktok.profile_views) {
    fields.push({
      header: "Profile views",
      value: prettifyNumber(user.user_tiktok.profile_views)
    });
  }

  if (user.user_tiktok.shares) {
    fields.push({
      header: "Shares",
      value: prettifyNumber(user.user_tiktok.shares)
    });
  }

  if (user.user_tiktok.video_views) {
    fields.push({
      header: "Video views",
      value: prettifyNumber(user.user_tiktok.video_views)
    });
  }

  if (user.user_tiktok.audience_countries) {
    const countries = String(user.user_tiktok.audience_countries)
      .split(",")
      .map(x => x.split("|#|"))
      .sort(([, a], [, b]) => Number(b) - Number(a))
      .slice(0, 4)
      .map(([country, value]) => (
        <>
          {String(country)} {prettifyNumber(Number(value), { percentages: true })}
          <br key={`${country} ${value}`} />
        </>
      ));

    fields.push({
      header: "Top audience countries",
      value: countries
    });
  }

  if (user.user_tiktok.audience_genders) {
    const genders = String(user.user_tiktok.audience_genders)
      .split(",")
      .map(x => x.split("|#|"))
      .sort(([, a], [, b]) => Number(b) - Number(a))
      .map(([gender, value]) => (
        <>
          {String(gender)} {prettifyNumber(Number(value), { percentages: true })}
          <br key={`${gender} ${value}`} />
        </>
      ));

    fields.push({
      header: "Audience genders",
      value: genders
    });
  }

  if (user.user_tiktok.audience_activity) {
    const topHours = String(user.user_tiktok.audience_activity)
      .split(",")
      .map(x => x.split("|#|"))
      .sort(([, a], [, b]) => Number(b) - Number(a))
      .slice(0, 5)
      .map(([hour, value]) => (
        <>
          {`${hour}:00`} - {prettifyNumber(Number(value))}
          <br key={`${hour} ${value}`} />
        </>
      ));

    fields.push({
      header: "Top audience hours",
      value: topHours
    });
  }

  return fields;
};

const getYouTubeFields = ({
  user
}: {
  user: NonNullable<UserQuery["users_by_pk"]>;
}): FieldType[] => {
  if (!user.user_youtube) return [];

  const fields: FieldType[] = [];

  if (user.user_youtube.avg_views) {
    fields.push({
      header: "Avg views",
      value: prettifyNumber(user.user_youtube.avg_views)
    });
  }

  if (user.user_youtube.avg_likes) {
    fields.push({
      header: "Avg likes",
      value: prettifyNumber(user.user_youtube.avg_likes)
    });
  }

  if (user.user_youtube.avg_comments) {
    fields.push({
      header: "Avg comments",
      value: prettifyNumber(user.user_youtube.avg_comments)
    });
  }

  if (user.user_youtube.max_views) {
    fields.push({
      header: "Max views",
      value: prettifyNumber(Number(user.user_youtube.max_views))
    });
  }

  if (user.user_youtube.video_count) {
    fields.push({
      header: "Video count",
      value: prettifyNumber(Number(user.user_youtube.video_count))
    });
  }

  if (user.user_youtube.videos_per_week) {
    fields.push({
      header: "Videos per week",
      value: prettifyNumber(Number(user.user_youtube.videos_per_week))
    });
  }

  return fields;
};

export const getProfileExtraFields = ({
  user
}: {
  user: NonNullable<UserQuery["users_by_pk"]>;
}): FieldType[] => {
  return [
    ...getProfileFields({ user }),
    ...getGenericFields({ user }),
    ...getInstagramFields({ user }),
    ...getTikTokFields({ user }),
    ...getYouTubeFields({ user })
  ];
};
