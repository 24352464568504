import { useApolloClient } from "@apollo/client";
import { Add } from "@mui/icons-material";
import type { Campaign_User } from "@relatable/gql/generated-base";
import { objectValues } from "@relatable/helpers/objects";
import { Checkbox } from "@relatable/ui/Checkbox";
import { Popup } from "components/ui/Popup";
import { type FC, useState } from "react";
import {
  ContentApprovalOverviewDocument,
  useExcludeCampaignUserFromContentSettingsMutation
} from "./generated";
export type ExcludedUser = Pick<Campaign_User, "id"> & {
  excluded_content_setting_ids: number[];
  username: string;
};
export const IncludeCreatorsInContentSettingsPopup: FC<{
  contentSettingId: number;
  contentSettingName: string;
  campaignUsers: ExcludedUser[];
  onClose(): void;
}> = ({ onClose, contentSettingId, campaignUsers, contentSettingName }) => {
  const apolloClient = useApolloClient();
  const [mutate] = useExcludeCampaignUserFromContentSettingsMutation();

  const [state, setState] = useState(
    campaignUsers.reduce((acc, item) => ({ ...acc, [item.id]: false }), {})
  );

  const cu = campaignUsers.filter(u => u.excluded_content_setting_ids.includes(contentSettingId));

  const handleConfirm = async () => {
    for (const cu of campaignUsers) {
      if (state[cu.id])
        await mutate({
          variables: {
            contentSettingIds: cu.excluded_content_setting_ids.filter(
              id => id !== contentSettingId
            ),
            campaignUserId: cu.id
          }
        });
    }
    onClose();
    apolloClient.refetchQueries({ include: [ContentApprovalOverviewDocument] });
  };

  return (
    <Popup
      onAction={handleConfirm}
      actionLabel="Add users"
      actionIcon={<Add />}
      disabledAction={objectValues(state).every(v => !v)}
      title={`Add creators back to ${contentSettingName}`}
      onClose={onClose}
    >
      {cu.map(campaignUser => (
        <Checkbox
          key={campaignUser.id}
          checked={state[campaignUser.id]}
          onChange={value => setState(p => ({ ...p, [campaignUser.id]: value }))}
          label={campaignUser.username}
        />
      ))}
    </Popup>
  );
};
