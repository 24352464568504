import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignTiktokStatsQueryVariables = Types.Exact<{
  stubs: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type CampaignTiktokStatsQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, stub: string, target_reach?: number | null, min_max_publish_date: { __typename?: 'campaign_content_settings_aggregate', aggregate?: { __typename?: 'campaign_content_settings_aggregate_fields', min?: { __typename?: 'campaign_content_settings_min_fields', publish_date_min?: string | null } | null, max?: { __typename?: 'campaign_content_settings_max_fields', publish_date_max?: string | null } | null } | null }, campaign_content_settings_aggregate: { __typename?: 'campaign_content_settings_aggregate', aggregate?: { __typename?: 'campaign_content_settings_aggregate_fields', sum?: { __typename?: 'campaign_content_settings_sum_fields', tiktoksCount?: number | null } | null } | null }, campaign_tiktoks: Array<{ __typename?: 'campaign_tiktok', id: number, approved: boolean, views?: number | null, likes?: number | null, comment_count?: number | null, not_found: boolean, created_at: string, track_url?: string | null, actual_reach?: number | null, average_watch_time?: number | null, completion_rate?: number | null, following_rate?: number | null, for_you_page_rate?: number | null, personal_profile_rate?: number | null, shares?: number | null, link_clicks?: number | null, saves?: number | null, notes: string, lock_statistics_at?: string | null, shortcode?: string | null, duration?: number | null, total_time_watched?: number | null, impression_sources?: Array<string> | null, audience_countries?: Array<string> | null, user_tiktok: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null, access_tokens_tiktok?: { __typename?: 'access_tokens_tiktok', id: number, is_valid: boolean } | null, user?: { __typename?: 'users', id: number } | null } }> }> };

export type AddCampaignTikTokMutationVariables = Types.Exact<{
  campaignStub: Types.Scalars['String']['input'];
  url: Types.Scalars['String']['input'];
  filename?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type AddCampaignTikTokMutation = { __typename?: 'Mutation', addCampaignTikTok?: void | null };

export type UpdateTikTokStatisticsMutationVariables = Types.Exact<{
  campaignTikTokId: Types.Scalars['Int']['input'];
}>;


export type UpdateTikTokStatisticsMutation = { __typename?: 'Mutation', updateTikTokStatistics?: void | null };

export type UserTikTokByUsernameQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
  campaignStub: Types.Scalars['String']['input'];
}>;


export type UserTikTokByUsernameQuery = { __typename?: 'Query', user_tiktok: Array<{ __typename?: 'user_tiktok', id: number, access_tokens_tiktok?: { __typename?: 'access_tokens_tiktok', is_valid: boolean } | null, user?: { __typename?: 'users', id: number, campaign_users: Array<{ __typename?: 'campaign_user', id: number, skip_api_verification: boolean }> } | null }> };


export const CampaignTiktokStatsDocument = gql`
    query campaignTiktokStats($stubs: [String!]!) {
  campaigns: campaign(where: {stub: {_in: $stubs}}) {
    id
    stub
    target_reach
    min_max_publish_date: campaign_content_settings_aggregate {
      aggregate {
        min {
          publish_date_min
        }
        max {
          publish_date_max
        }
      }
    }
    campaign_content_settings_aggregate(where: {type: {_eq: "tiktok"}}) {
      aggregate {
        sum {
          tiktoksCount: number_of_posts
        }
      }
    }
    campaign_tiktoks {
      id
      approved
      views
      likes
      comment_count
      not_found
      created_at
      likes
      track_url
      actual_reach
      average_watch_time
      completion_rate
      following_rate
      for_you_page_rate
      personal_profile_rate
      shares
      link_clicks
      saves
      notes
      lock_statistics_at
      shortcode
      duration
      total_time_watched
      impression_sources
      audience_countries
      user_tiktok {
        id
        username
        followed_by
        access_tokens_tiktok {
          id
          is_valid
        }
        user {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignTiktokStatsQuery__
 *
 * To run a query within a React component, call `useCampaignTiktokStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignTiktokStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignTiktokStatsQuery({
 *   variables: {
 *      stubs: // value for 'stubs'
 *   },
 * });
 */
export function useCampaignTiktokStatsQuery(baseOptions: Apollo.QueryHookOptions<CampaignTiktokStatsQuery, CampaignTiktokStatsQueryVariables> & ({ variables: CampaignTiktokStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignTiktokStatsQuery, CampaignTiktokStatsQueryVariables>(CampaignTiktokStatsDocument, options);
      }
export function useCampaignTiktokStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignTiktokStatsQuery, CampaignTiktokStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignTiktokStatsQuery, CampaignTiktokStatsQueryVariables>(CampaignTiktokStatsDocument, options);
        }
export function useCampaignTiktokStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignTiktokStatsQuery, CampaignTiktokStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignTiktokStatsQuery, CampaignTiktokStatsQueryVariables>(CampaignTiktokStatsDocument, options);
        }
export type CampaignTiktokStatsQueryHookResult = ReturnType<typeof useCampaignTiktokStatsQuery>;
export type CampaignTiktokStatsLazyQueryHookResult = ReturnType<typeof useCampaignTiktokStatsLazyQuery>;
export type CampaignTiktokStatsSuspenseQueryHookResult = ReturnType<typeof useCampaignTiktokStatsSuspenseQuery>;
export type CampaignTiktokStatsQueryResult = Apollo.QueryResult<CampaignTiktokStatsQuery, CampaignTiktokStatsQueryVariables>;
export const AddCampaignTikTokDocument = gql`
    mutation addCampaignTikTok($campaignStub: String!, $url: String!, $filename: String) {
  addCampaignTikTok(campaign_stub: $campaignStub, url: $url, filename: $filename)
}
    `;
export type AddCampaignTikTokMutationFn = Apollo.MutationFunction<AddCampaignTikTokMutation, AddCampaignTikTokMutationVariables>;

/**
 * __useAddCampaignTikTokMutation__
 *
 * To run a mutation, you first call `useAddCampaignTikTokMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCampaignTikTokMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCampaignTikTokMutation, { data, loading, error }] = useAddCampaignTikTokMutation({
 *   variables: {
 *      campaignStub: // value for 'campaignStub'
 *      url: // value for 'url'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useAddCampaignTikTokMutation(baseOptions?: Apollo.MutationHookOptions<AddCampaignTikTokMutation, AddCampaignTikTokMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCampaignTikTokMutation, AddCampaignTikTokMutationVariables>(AddCampaignTikTokDocument, options);
      }
export type AddCampaignTikTokMutationHookResult = ReturnType<typeof useAddCampaignTikTokMutation>;
export type AddCampaignTikTokMutationResult = Apollo.MutationResult<AddCampaignTikTokMutation>;
export type AddCampaignTikTokMutationOptions = Apollo.BaseMutationOptions<AddCampaignTikTokMutation, AddCampaignTikTokMutationVariables>;
export const UpdateTikTokStatisticsDocument = gql`
    mutation updateTikTokStatistics($campaignTikTokId: Int!) {
  updateTikTokStatistics(id: $campaignTikTokId)
}
    `;
export type UpdateTikTokStatisticsMutationFn = Apollo.MutationFunction<UpdateTikTokStatisticsMutation, UpdateTikTokStatisticsMutationVariables>;

/**
 * __useUpdateTikTokStatisticsMutation__
 *
 * To run a mutation, you first call `useUpdateTikTokStatisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTikTokStatisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTikTokStatisticsMutation, { data, loading, error }] = useUpdateTikTokStatisticsMutation({
 *   variables: {
 *      campaignTikTokId: // value for 'campaignTikTokId'
 *   },
 * });
 */
export function useUpdateTikTokStatisticsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTikTokStatisticsMutation, UpdateTikTokStatisticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTikTokStatisticsMutation, UpdateTikTokStatisticsMutationVariables>(UpdateTikTokStatisticsDocument, options);
      }
export type UpdateTikTokStatisticsMutationHookResult = ReturnType<typeof useUpdateTikTokStatisticsMutation>;
export type UpdateTikTokStatisticsMutationResult = Apollo.MutationResult<UpdateTikTokStatisticsMutation>;
export type UpdateTikTokStatisticsMutationOptions = Apollo.BaseMutationOptions<UpdateTikTokStatisticsMutation, UpdateTikTokStatisticsMutationVariables>;
export const UserTikTokByUsernameDocument = gql`
    query userTikTokByUsername($username: String!, $campaignStub: String!) {
  user_tiktok(where: {username: {_eq: $username}}, limit: 1) {
    id
    access_tokens_tiktok {
      is_valid
    }
    user {
      id
      campaign_users(where: {campaign: {stub: {_eq: $campaignStub}}}) {
        id
        skip_api_verification
      }
    }
  }
}
    `;

/**
 * __useUserTikTokByUsernameQuery__
 *
 * To run a query within a React component, call `useUserTikTokByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTikTokByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTikTokByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useUserTikTokByUsernameQuery(baseOptions: Apollo.QueryHookOptions<UserTikTokByUsernameQuery, UserTikTokByUsernameQueryVariables> & ({ variables: UserTikTokByUsernameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTikTokByUsernameQuery, UserTikTokByUsernameQueryVariables>(UserTikTokByUsernameDocument, options);
      }
export function useUserTikTokByUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTikTokByUsernameQuery, UserTikTokByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTikTokByUsernameQuery, UserTikTokByUsernameQueryVariables>(UserTikTokByUsernameDocument, options);
        }
export function useUserTikTokByUsernameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserTikTokByUsernameQuery, UserTikTokByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserTikTokByUsernameQuery, UserTikTokByUsernameQueryVariables>(UserTikTokByUsernameDocument, options);
        }
export type UserTikTokByUsernameQueryHookResult = ReturnType<typeof useUserTikTokByUsernameQuery>;
export type UserTikTokByUsernameLazyQueryHookResult = ReturnType<typeof useUserTikTokByUsernameLazyQuery>;
export type UserTikTokByUsernameSuspenseQueryHookResult = ReturnType<typeof useUserTikTokByUsernameSuspenseQuery>;
export type UserTikTokByUsernameQueryResult = Apollo.QueryResult<UserTikTokByUsernameQuery, UserTikTokByUsernameQueryVariables>;