import styled from "@emotion/styled";
import { maybePluralize, sum } from "@relatable/helpers";

import { Headline } from "components/ui/Headline";
import { getInfluencerMarketingBudget } from "lib/calculations";

import type { OverviewQuery } from "../generated";
import { ProjectRow } from "./ProjectRow";

type ClientCardProps = {
  name: string;
  projects: OverviewQuery["clients"][number]["hubspot_deals"][number]["project"][];
  activeProjectStub?: string;
};

export const ClientCard: React.FC<ClientCardProps> = ({ name, projects, activeProjectStub }) => {
  const campaignsCount = projects.reduce(
    (acc: number, p) => acc + (p.campaigns_aggregate?.aggregate?.count || 0),
    0
  );

  return (
    <Root>
      <TitleContainer>
        <Headline variant="h4">{name}</Headline>
        <span className="count-info">
          {maybePluralize(projects.length, { noun: "project" })} •{" "}
          {maybePluralize(campaignsCount, { noun: "campaign" })}{" "}
        </span>
      </TitleContainer>

      {projects.map(project => {
        const participantsCount = project.campaigns_aggregate.nodes.reduce(
          (acc, n) => acc + (n.campaign_participants_aggregate.aggregate?.count || 0),
          0
        );

        return (
          <ProjectRow
            archived={Boolean(project.archived)}
            agreement_url={project.agreement_url || ""}
            partialCampaigns={project.campaigns_aggregate.nodes}
            campaignsAggregate={project.campaigns_aggregate.aggregate}
            influencerMarketingBudget={getInfluencerMarketingBudget(project.im_project_budgets)}
            key={project.id}
            name={project.name}
            participantsCount={participantsCount}
            participantsReach={participantsCount}
            payoutBudgetUsed={sum(
              project.campaigns_aggregate.nodes.map(c => {
                const expenses = c.campaign_expenses_aggregate.aggregate?.sum?.amount || 0;
                const payments =
                  c.campaign_participants_aggregate.aggregate?.sum?.local_invoice_pay || 0;
                return expenses + payments / (c.exchange_rate || 1);
              })
            )}
            project_team_members={project.project_team_members}
            legacyTimeTracking={project.project_time_tracking}
            totalHours={project.time_tracking_aggregate.aggregate?.sum?.hours || 0}
            proposal_url={project.proposal_url || ""}
            stub={project.stub || ""}
            target_reach={project.target_reach || 0}
            target_views={project.target_views || 0}
            defaultOpen={activeProjectStub === project.stub}
          />
        );
      })}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  padding: 2em 0;

  &:first-of-type {
    padding-top: 0em;
  }

  &:last-of-type {
    padding-bottom: 0em;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  .count-info {
    margin-left: 1.5em;
  }
`;
