import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllCampaignsListQueryVariables = Types.Exact<{
  where: Types.Campaign_Bool_Exp;
}>;


export type AllCampaignsListQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, stub: string, title: string, platform?: Types.Platform_Enum | null, max_budget?: number | null, creative_brief_url?: string | null, market_ids: Array<number>, campaign_content_settings_aggregate: { __typename?: 'campaign_content_settings_aggregate', aggregate?: { __typename?: 'campaign_content_settings_aggregate_fields', min?: { __typename?: 'campaign_content_settings_min_fields', publish_date_min?: string | null } | null, max?: { __typename?: 'campaign_content_settings_max_fields', publish_date_max?: string | null } | null, sum?: { __typename?: 'campaign_content_settings_sum_fields', number_of_posts?: number | null } | null } | null }, project: { __typename?: 'project', id: number, name: string, stub: string, proposal_url?: string | null, agreement_url?: string | null, agreement_attachment_url?: string | null, can_use_for_marketing: boolean, organic_usage_period: string, content_right_period: string, campaigns: Array<{ __typename?: 'campaign', id: number, campaign_timeline: Array<{ __typename?: 'campaign_timeline', group: number, end_date?: string | null }> }>, project_attachments: Array<{ __typename?: 'project_attachments', id: number, link: string, name: string }>, project_budgets: Array<{ __typename?: 'project_budget', id: number }> }, campaign_users: Array<{ __typename?: 'campaign_user', id: number }> }> };


export const AllCampaignsListDocument = gql`
    query allCampaignsList($where: campaign_bool_exp!) {
  campaigns: campaign(where: $where, order_by: {id: desc}) {
    id
    stub
    title
    platform
    max_budget
    creative_brief_url
    market_ids
    campaign_content_settings_aggregate {
      aggregate {
        min {
          publish_date_min
        }
        max {
          publish_date_max
        }
        sum {
          number_of_posts
        }
      }
    }
    project {
      id
      name
      stub
      proposal_url
      agreement_url
      agreement_attachment_url
      can_use_for_marketing
      organic_usage_period
      content_right_period
      campaigns {
        id
        campaign_timeline(order_by: {group: asc}) {
          group
          end_date
        }
      }
      project_attachments {
        id
        link
        name
      }
      project_budgets(where: {type: {_eq: "amplify"}}, limit: 1) {
        id
      }
    }
    campaign_users(where: {state: {_in: ["confirmed", "approved"]}}) {
      id
    }
  }
}
    `;

/**
 * __useAllCampaignsListQuery__
 *
 * To run a query within a React component, call `useAllCampaignsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCampaignsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCampaignsListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllCampaignsListQuery(baseOptions: Apollo.QueryHookOptions<AllCampaignsListQuery, AllCampaignsListQueryVariables> & ({ variables: AllCampaignsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCampaignsListQuery, AllCampaignsListQueryVariables>(AllCampaignsListDocument, options);
      }
export function useAllCampaignsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCampaignsListQuery, AllCampaignsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCampaignsListQuery, AllCampaignsListQueryVariables>(AllCampaignsListDocument, options);
        }
export function useAllCampaignsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllCampaignsListQuery, AllCampaignsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllCampaignsListQuery, AllCampaignsListQueryVariables>(AllCampaignsListDocument, options);
        }
export type AllCampaignsListQueryHookResult = ReturnType<typeof useAllCampaignsListQuery>;
export type AllCampaignsListLazyQueryHookResult = ReturnType<typeof useAllCampaignsListLazyQuery>;
export type AllCampaignsListSuspenseQueryHookResult = ReturnType<typeof useAllCampaignsListSuspenseQuery>;
export type AllCampaignsListQueryResult = Apollo.QueryResult<AllCampaignsListQuery, AllCampaignsListQueryVariables>;