import type {
  Campaign_Content_Settings,
  Campaign_Content_Settings_Insert_Input,
  Campaign_Content_Settings_Set_Input
} from "@relatable/gql/generated-base";
import { CONTENT_TYPES } from "@relatable/helpers/constants";
import { YYYY_MM_DD } from "@relatable/helpers/date";
import { Button } from "@relatable/ui/Button";
import { DateInput } from "@relatable/ui/DateInput";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";
import { Fragment, forwardRef, useImperativeHandle, useState } from "react";

import { Section, SubSection } from "modules/project/Section";
import { getChangedFields } from "modules/project/helpers";

import { Add, Delete } from "@mui/icons-material";
import type { SectionRef } from "../types";
import {
  useCampaignContentSettingsQuery,
  useDeleteCampaignContentSettingMutation,
  useInsertCampaignContentSettingMutation,
  useUpdateCampaignContentSettingMutation
} from "./generated";

export type ContentFields = Array<
  Pick<
    Campaign_Content_Settings,
    "type" | "publish_date_min" | "publish_date_max" | "number_of_posts" | "name"
  > & {
    id?: number;
  }
>;

export const ContentSection = forwardRef<SectionRef<ContentFields>, { campaignStub: string }>(
  ({ campaignStub }, ref) => {
    const [fieldErrors, setFieldErrors] = useState<string[]>([]);

    const [contentSettings, setContentSettings] = useState<ContentFields>([]);

    const { data, refetch } = useCampaignContentSettingsQuery({
      variables: { stub: campaignStub },
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ campaigns }) => {
        const [c] = campaigns;
        setContentSettings(c.campaign_content_settings);
      }
    });

    const [deleteContentSettingMutation, deleteContentSettingMutationOptions] =
      useDeleteCampaignContentSettingMutation();
    const [updateContentSettingMutation, updateContentSettingMutationOptions] =
      useUpdateCampaignContentSettingMutation();
    const [insertContentSettingMutation, insertContentSettingMutationOptions] =
      useInsertCampaignContentSettingMutation();

    const validate = () => {
      const errors: string[] = [];
      contentSettings.forEach((s, index) => {
        const name = `Content setting #${index}`;
        if (!s.number_of_posts) errors.push(`${name} is missing number of posts`);
        if (s.number_of_posts < 1) errors.push(`${name}: number of posts must be greater than 0`);
        if (!s.publish_date_max) errors.push(`${name} is missing max publish date`);
        if (!s.publish_date_min) errors.push(`${name} is missing min publish date`);
      });
      setFieldErrors(errors);
      return errors;
    };

    const campaign = data?.campaigns[0];

    const contentSettingsToInsert: Campaign_Content_Settings_Insert_Input[] = [];
    const contentSettingToUpdate: { id: number; set: Campaign_Content_Settings_Set_Input }[] = [];
    const contentSettingIdsToDelete =
      campaign?.campaign_content_settings
        .filter(c => !contentSettings.some(i => i.id === c.id))
        .map(i => i.id) || [];

    contentSettings.forEach(c => {
      const existing = campaign?.campaign_content_settings.find(cs => cs.id === c.id);
      if (!existing) {
        contentSettingsToInsert.push({ campaign_id: campaign?.id, ...c });
        return;
      }

      const changedFields = getChangedFields({ data: c, initialData: existing });
      if (!Object.values(changedFields).length) return;
      contentSettingToUpdate.push({
        id: existing.id,
        set: changedFields
      });
    });

    const handleUpdate = async () => {
      if (!campaign) throw new Error("missing campaign");
      if (validate().length) return;

      await Promise.all([
        ...contentSettingIdsToDelete.map(id => deleteContentSettingMutation({ variables: { id } })),
        ...contentSettingsToInsert.map(object =>
          insertContentSettingMutation({
            variables: {
              object
            }
          })
        ),
        ...contentSettingToUpdate.map(async ({ id, set }) => {
          await updateContentSettingMutation({
            variables: {
              id,
              set: { ...set, updated_at: new Date().toISOString() }
            }
          });
        })
      ]);

      await refetch();
    };

    const submitError =
      deleteContentSettingMutationOptions.error ||
      updateContentSettingMutationOptions.error ||
      insertContentSettingMutationOptions.error;

    const isChanged = Boolean(
      campaign
        ? contentSettingsToInsert.length ||
            contentSettingToUpdate.length ||
            contentSettingIdsToDelete.length
        : false
    );

    useImperativeHandle(ref, () => ({
      submit: handleUpdate,
      validate,
      getFields: () => contentSettings,
      setFields: setContentSettings
    }));

    return (
      <Section
        title="Content"
        fieldErrors={fieldErrors}
        isChanged={isChanged}
        submitError={submitError}
        updated_at={campaign?.campaign_content_settings.reduce(
          (acc, i) => {
            const itemDate = new Date(i.updated_at || i.created_at);
            if (!acc) {
              return itemDate.toISOString();
            }
            if (new Date(acc) < itemDate) {
              return itemDate.toISOString();
            }
            return acc;
          },
          undefined as undefined | string
        )}
        sidebar={
          <Note variant="info" label="#Of content">
            Feed video and photo have fixed number of content.
          </Note>
        }
      >
        {contentSettings.map((s, index) => {
          const handleChange = (newS: Partial<(typeof contentSettings)[number]>) => {
            setContentSettings(
              contentSettings.map((prevS, _index) =>
                _index === index ? { ...prevS, ...newS } : prevS
              )
            );
          };
          const isEditableNumberOfContent = ![
            CONTENT_TYPES.PHOTO.value,
            CONTENT_TYPES.VIDEO.value
          ].includes(s.type);
          return (
            <Fragment key={index}>
              <SubSection>
                <Select
                  required
                  style={{ width: "100%" }}
                  hideNone
                  label="Select the content type"
                  value={s.type}
                  onChange={type =>
                    type &&
                    handleChange({
                      type: type as Campaign_Content_Settings["type"],
                      ...(!isEditableNumberOfContent ? { number_of_posts: 1 } : {})
                    })
                  }
                  options={Object.values(CONTENT_TYPES)
                    .filter(({ value }) => {
                      const platform = campaign?.platform;
                      switch (platform) {
                        case "youtube":
                          return value === CONTENT_TYPES.YOUTUBE.value;
                        case "tiktok":
                          return value === CONTENT_TYPES.TIKTOK.value;
                        case "snapchat":
                          return value === CONTENT_TYPES.SNAP.value;
                        case "instagram":
                          return ![
                            CONTENT_TYPES.YOUTUBE.value,
                            CONTENT_TYPES.TIKTOK.value,
                            CONTENT_TYPES.SNAP.value
                          ].includes(value);

                        default:
                          return true;
                      }
                    })
                    .map(({ value, label }) => ({
                      value,
                      label
                    }))}
                />
                <TextInput
                  required
                  style={{ width: "100%" }}
                  label="# of content"
                  disabled={!isEditableNumberOfContent}
                  value={isEditableNumberOfContent ? String(s.number_of_posts) : "1"}
                  onChange={v => handleChange({ number_of_posts: Number(v) })}
                />
                <TextInput
                  style={{ width: "100%" }}
                  label="Name"
                  value={s.name || ""}
                  onChange={v => handleChange({ name: v })}
                />
              </SubSection>

              <SubSection>
                <DateInput
                  style={{ width: "100%" }}
                  label="First publication*"
                  value={s.publish_date_min}
                  onChange={v => handleChange({ publish_date_min: v })}
                />
                <DateInput
                  style={{ width: "100%" }}
                  label="Last publication*"
                  value={s.publish_date_max}
                  onChange={v => handleChange({ publish_date_max: v })}
                />
                <Delete
                  style={{ cursor: "pointer", color: palette.primary.red }}
                  onClick={() =>
                    setContentSettings(prev => prev.filter((_, _index) => index !== _index))
                  }
                />
              </SubSection>
            </Fragment>
          );
        })}

        <div />
        <Button
          size="medium"
          style={{ margin: "auto" }}
          icon={<Add />}
          onClick={() =>
            setContentSettings(prev => [
              ...prev,
              {
                number_of_posts: 1,
                publish_date_max: YYYY_MM_DD(new Date()),
                publish_date_min: YYYY_MM_DD(new Date()),
                type: "photo"
              }
            ])
          }
        >
          Add content
        </Button>
      </Section>
    );
  }
);
