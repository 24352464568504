import { useMemo, useState } from "react";

import styled from "@emotion/styled";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { getContentAggregateState, prettifyDate } from "@relatable/helpers";
import { Table, type TableColumn } from "@relatable/ui/Table";

import { Add, DeleteOutline } from "@mui/icons-material";
import { objectEntries } from "@relatable/helpers/objects";
import { Button } from "@relatable/ui/Button";
import { StateBadge } from "../common/StateBadge";
import { getTypeLabel } from "../common/utils";
import { ContentApprovalProgress } from "./ContentApprovalProgress";
import {
  type ExcludedUser,
  IncludeCreatorsInContentSettingsPopup
} from "./IncludeCreatorsInContentSettingsPopup";
import { Toolbar } from "./common/Toolbar";
import { type UserContentStatus, getProgressData } from "./common/reshape_data";
import {
  ContentApprovalOverviewDocument,
  useExcludeCampaignUserFromContentSettingsMutation
} from "./generated";
import type { Filter } from "./types";

export const Overview: React.FC<{
  platform: Platform_Enum | null | undefined;
  onDownload: () => void;
  onRowClick: (row: { userId: number; contentSettingId: number }) => void;
  usersContentStatus: UserContentStatus[];
  filteredUsersContentStatus: UserContentStatus[];
  workspaceUrls: string[];
  pushNotificationToClient: () => void;
  onSetFilters: (f: Filter) => void;
  filters: Filter;
  notifyClientLoading: boolean;
}> = ({
  platform,
  onDownload,
  onRowClick,
  usersContentStatus,
  filteredUsersContentStatus,
  workspaceUrls,
  pushNotificationToClient,
  notifyClientLoading,
  onSetFilters,
  filters
}) => {
  const {
    uniqueParticipantsCount,
    approvedContentCount,
    submittedContentCount,
    approvedParticipantsCount
  } = useMemo(() => getProgressData(usersContentStatus), [usersContentStatus]);

  const [includeCreatorsPopupId, setIncludeCreatorsPopupId] = useState<number | null>(null);

  const [excludeCampaignUserFromContentSettings, { loading }] =
    useExcludeCampaignUserFromContentSettingsMutation({
      awaitRefetchQueries: true,
      refetchQueries: [ContentApprovalOverviewDocument]
    });

  const uniqueDateIntervals = Array.from(
    new Set(usersContentStatus.map(row => `${row.publish_date_min},${row.publish_date_max}`))
  ).sort();

  const rows = filteredUsersContentStatus.map(row => {
    const state = (() => {
      const itemStates: (string | null | undefined)[] = [];
      if (row.content_approval_state?.content_preapproval_caption) {
        itemStates.push(
          row.content_approval_state.content_preapproval_caption.content_preapproval_logs[0]?.state
        );
      }
      if (
        Array.isArray(row.content_approval_state?.campaign_preapproval_content_medias) &&
        row.content_approval_state?.campaign_preapproval_content_medias.length > 0
      ) {
        itemStates.push(
          ...row.content_approval_state.campaign_preapproval_content_medias.map(
            m => m.content_preapproval_logs[0]?.state
          )
        );
      }
      return getContentAggregateState(itemStates);
    })();

    return {
      key: `${row.user_id}${row.content_setting_id}`,
      userLabel: row.userLabel,
      userLink: row.userLink,
      type: row.type,
      state,
      publishDateMin: row.publish_date_min,
      publishDateMax: row.publish_date_max,
      userId: row.user_id,
      contentId: row.content_id,
      contentSettingId: row.content_setting_id,
      contentSettingName: row.contentSettingName,
      isSubHeader: false,
      campaignUserId: row.campaignUserId,
      excludedContentSettingIds: row.excludedContentSettingIds
    };
  });

  const { groupedRows, excludedUsers } = rows.reduce<{
    groupedRows: Record<number, typeof rows>;
    excludedUsers: Record<number, ExcludedUser[]>;
  }>(
    (acc, item) => {
      if (!item.contentSettingId) return acc;

      return {
        ...acc,
        groupedRows: {
          ...acc.groupedRows,
          [item.contentSettingId]: [...(acc.groupedRows[item.contentSettingId] || []), item]
        },
        excludedUsers: {
          ...acc.excludedUsers,
          [item.contentSettingId]: item.excludedContentSettingIds.includes(item.contentSettingId)
            ? [
                ...(acc.excludedUsers[item.contentSettingId] || []),

                {
                  excluded_content_setting_ids: item.excludedContentSettingIds,
                  username: item.userLabel,
                  id: item.campaignUserId
                }
              ]
            : acc.excludedUsers[item.contentSettingId]
        }
      };
    },
    { groupedRows: {}, excludedUsers: {} }
  );

  const columns: TableColumn<typeof rows>[] = [
    {
      field: "userLabel",
      headerName: "Participant",
      renderCell: ({ value, row }) => {
        if (row.isSubHeader) {
          return (
            <span>
              <span style={{ fontWeight: "bold", fontSize: 18, marginRight: 10 }}>
                {row.contentSettingName}
              </span>

              <span>
                {row.publishDateMax === row.publishDateMin
                  ? prettifyDate(row.publishDateMin)
                  : `${prettifyDate(row.publishDateMin)} : ${prettifyDate(row.publishDateMax)}`}
              </span>

              {row.contentSettingId && excludedUsers[row.contentSettingId] && (
                <Button
                  style={{ marginTop: -3, marginLeft: 10 }}
                  onClick={() => setIncludeCreatorsPopupId(row.contentSettingId || null)}
                  icon={<Add />}
                  variant="text"
                >
                  Add creators
                </Button>
              )}
            </span>
          );
        }

        return (
          <>
            <Button
              tooltipText="Remove creator from the batch"
              variant="text"
              icon={<DeleteOutline color="primary" />}
              disabled={loading}
              onAsyncClick={e => {
                e.stopPropagation();
                return excludeCampaignUserFromContentSettings({
                  variables: {
                    campaignUserId: row.campaignUserId,
                    contentSettingIds: [
                      ...row.excludedContentSettingIds,
                      row.contentSettingId
                    ].filter(Boolean) as number[]
                  }
                });
              }}
            />

            {/* No need for a click action here - event will be propagated and trigger a link to their approval page */}
            <Button onClick={() => {}} disabled={loading} variant="text">
              {value}
            </Button>
          </>
        );
      }
    },
    {
      field: "type",
      headerName: "Content type",
      renderCell: ({ value, row }) =>
        row.isSubHeader ? null : getTypeLabel({ type: value, platform })
    },
    {
      field: "state",
      headerName: "Content state",
      renderCell: ({ value, row }) =>
        row.isSubHeader ? null : (
          <div style={{ display: "inline-block" }}>
            <StateBadge state={value} />
          </div>
        )
    }
  ];

  return (
    <Root>
      <TopBarContainer>
        <ContentApprovalProgress
          approvedParticipantsCount={approvedParticipantsCount}
          participantsCount={uniqueParticipantsCount}
          approvedContentCount={approvedContentCount}
          totalContentCount={usersContentStatus.length}
          submittedContentCount={submittedContentCount}
        />
        <Toolbar
          workspaceUrls={workspaceUrls}
          uniqueDateIntervals={uniqueDateIntervals}
          onDownload={platform === "instagram" ? onDownload : null}
          pushNotificationToClient={pushNotificationToClient}
          notifyClientLoading={notifyClientLoading}
          onSetFilters={onSetFilters}
          filters={filters}
        />
      </TopBarContainer>

      {includeCreatorsPopupId && (
        <IncludeCreatorsInContentSettingsPopup
          onClose={() => setIncludeCreatorsPopupId(null)}
          campaignUsers={excludedUsers[includeCreatorsPopupId]}
          contentSettingId={includeCreatorsPopupId}
          contentSettingName={
            rows.find(r => r.contentSettingId === includeCreatorsPopupId)?.contentSettingName || ""
          }
        />
      )}

      <Table
        loading={loading}
        tableId="content-approval-overview"
        stickyColumn
        columns={columns}
        rows={objectEntries(groupedRows).flatMap(([id, r], index) => {
          // @ts-ignore
          const subHeader: (typeof r)[number] = {
            key: String(id),
            isSubHeader: true,
            contentSettingName: r[0].contentSettingName || `Batch: ${index + 1}`,
            publishDateMin: r[0].publishDateMin,
            publishDateMax: r[0].publishDateMax,
            userId: r[0].userId,
            contentSettingId: r[0].contentSettingId
          };

          return [
            subHeader,
            ...r.filter(i => {
              if (!subHeader.contentSettingId) return true;
              return !i.excludedContentSettingIds.includes(subHeader.contentSettingId);
            })
          ];
        })}
        onRowClick={row => {
          if (row.isSubHeader) return;
          return onRowClick({
            userId: row.userId,
            contentSettingId: Number(row.contentSettingId)
          });
        }}
      />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBarContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px;
  display: flex;
  flex-direction: column;
`;
