import { uniqBy, unique } from "@relatable/helpers/index";
import { TextInput } from "@relatable/ui/TextInput";
import { Autocomplete } from "components/ui/Autocomplete";
import type { FC } from "react";
import { useHubspotDealsQuery } from "./generated";

const HubspotItem: FC<{ hubspotId: string | null; onChange(v: string | null): void }> = ({
  hubspotId,
  onChange
}) => {
  const isAddingNewDeal = hubspotId === null;

  const { data: selectedHubSpotDeal } = useHubspotDealsQuery({
    variables: { search: hubspotId },
    skip: isAddingNewDeal
  });
  const {
    data: hubspotDeals,
    previousData: prevHubspotDeals,
    refetch
  } = useHubspotDealsQuery({
    variables: {},
    fetchPolicy: "cache-first"
  });

  return (
    <>
      <TextInput
        disabled
        required
        label="Hubspot deal ID (automatically set)"
        value={hubspotId || ""}
        onChange={() => null}
      />

      {selectedHubSpotDeal || isAddingNewDeal ? (
        <Autocomplete
          key="hubspot_deal_id"
          label="Hubspot deal name *"
          onChange={v => onChange(v)}
          value={hubspotId}
          onInputChange={search => refetch({ search })}
          uncontrolled={true}
          options={uniqBy(
            [
              ...(selectedHubSpotDeal?.hubspotDeals || []),
              ...(hubspotDeals?.hubspotDeals || []),
              ...(prevHubspotDeals?.hubspotDeals || [])
            ],
            "dealId"
          ).map(deal => ({
            id: deal.dealId,
            label: `${deal.name} [${deal.dealId}]`
          }))}
        />
      ) : (
        <Autocomplete
          key="hubspot_deal_id_placeholder"
          label={isAddingNewDeal ? "Search for a new deal" : "Loading Hubspot deals…"}
          onChange={() => {}}
          value={null}
          options={[]}
        />
      )}
    </>
  );
};

export const HubspotSection: FC<{ hubspotDealIds: string[]; onChange(ids: string[]): void }> = ({
  hubspotDealIds,
  onChange
}) => {
  return (
    <>
      {hubspotDealIds.map(id => (
        <HubspotItem
          key={id}
          hubspotId={id}
          onChange={v =>
            onChange(
              unique([...hubspotDealIds.filter(i => i !== id), v].filter(Boolean) as string[])
            )
          }
        />
      ))}

      <HubspotItem
        key={hubspotDealIds.length}
        hubspotId={null}
        onChange={v => onChange(unique([...hubspotDealIds, v].filter(Boolean) as string[]))}
      />
    </>
  );
};
