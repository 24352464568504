import { Button, ButtonGroup, Chip, CircularProgress, Grid } from "@mui/material";
import { truncateFileName } from "@relatable/helpers";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";
import { TextInput } from "@relatable/ui/TextInput";
import { type FC, useState } from "react";
import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { Popup } from "components/ui/Popup";
import { UploadInput } from "components/ui/UploadInput";
import { useSignUploadUrlMutation } from "modules/generated";

import { Check, Delete, InsertDriveFile } from "@mui/icons-material";
import { useSnackbar } from "@relatable/ui/Snackbar";
import {
  CampaignTiktokStatsDocument,
  useAddCampaignTikTokMutation,
  useUserTikTokByUsernameLazyQuery
} from "./generated";

const useUploadTikTok = () => {
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const [signUploadUrl] = useSignUploadUrlMutation();
  const [addTikTok] = useAddCampaignTikTokMutation({
    refetchQueries: [CampaignTiktokStatsDocument]
  });

  return async ({
    fileType,
    file,
    videoUrl,
    fileName
  }: {
    fileType: string;
    file: File;
    videoUrl: string;
    fileName: string;
  }) => {
    if (!campaignStub) throw new Error("Missing campaign stub!");

    const match = videoUrl.match(/^https:\/\/www\.tiktok\.com\/@([^/]+)\/video\/([0-9]+)/);
    if (!match) throw new Error("Invalid TikTok URL!");

    const [, username, videoId] = match as string[];

    const { data } = await signUploadUrl({
      variables: {
        input: {
          fileName: `${username}/${username}-Tiktok-${new Date().getTime()}-${videoId}-${fileName}`,
          fileType,
          prefix: "CAMPAIGN_STORIES"
        }
      }
    });

    if (!data) throw new Error("Something went wrong when uploading a file");

    const { signedUploadUrl, url: contentUrl } = data.signUploadUrl;

    await fetch(signedUploadUrl, {
      method: "PUT",
      body: file,
      headers: { "content-type": file.type }
    });

    await addTikTok({ variables: { campaignStub, url: videoUrl, filename: contentUrl } });
  };
};

type FileStatus = "uploading" | "awaiting upload" | "uploaded";

const StatusIcon: FC<{ status: FileStatus }> = ({ status }) => {
  if (status === "uploaded") {
    return <Check style={{ marginRight: 5, color: palette.secondary.green }} />;
  }
  if (status === "uploading") return <CircularProgress size={10} style={{ marginRight: 5 }} />;
  return null;
};

export const UploadTikTokPopup: FC<{ onClose(): void; onUpload(): void }> = ({
  onClose,
  onUpload
}) => {
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const [videoUrl, setVideoUrl] = useState("");
  const [files, setFiles] = useState<{ content: File; status: FileStatus }[]>([]);
  const [hasValidToken, setIsValidToken] = useState(false);
  const snackbar = useSnackbar();

  const uploadTikTok = useUploadTikTok();

  const handleSubmit = async () => {
    setFiles(files.map(file => ({ ...file, status: "uploading" })));
    if (!videoUrl) throw Error("URL is required!");

    // to keep the order of uploading files we cannot use Promise.all
    for (const index in files) {
      const file = files[index];
      await uploadTikTok({
        fileType: file.content.type,
        file: file.content,
        videoUrl,
        fileName: file.content.name
      });

      setFiles(prev =>
        prev.map(prevFile =>
          prevFile.content.name + prevFile.content.lastModified ===
          file.content.name + file.content.lastModified
            ? { ...file, status: "uploaded" as const }
            : prevFile
        )
      );
    }

    onUpload();
    onClose();
  };

  const getMatch = (v: string) => v.match(/^https:\/\/www\.tiktok\.com\/@([^/]+)\/video\/([0-9]+)/);

  const [getUserTikTokByUsername, { loading }] = useUserTikTokByUsernameLazyQuery();
  const handleChange = async (v: string) => {
    setVideoUrl(v);

    const match = getMatch(v);
    if (match && campaignStub) {
      const [, username] = match as string[];
      const { data } = await getUserTikTokByUsername({ variables: { username, campaignStub } });
      const userTiktok = data?.user_tiktok[0];

      if (!userTiktok) {
        snackbar.error(`Not found a user with username ${username}`);
      }

      setIsValidToken(
        Boolean(
          userTiktok?.access_tokens_tiktok?.is_valid ||
            userTiktok?.user?.campaign_users[0].skip_api_verification
        )
      );
      return;
    }

    setIsValidToken(false);
  };

  const match = getMatch(videoUrl);

  return (
    <Popup
      allowOverflow
      maxWidth="sm"
      disabledAction={!files.length || !videoUrl || !match || !hasValidToken}
      onAction={handleSubmit}
      actionLabel="Submit"
      title="Upload TikTok"
      onClose={onClose}
    >
      <TextInput value={videoUrl} onChange={handleChange} label="TikTok Video URL" />

      <UploadInput
        multiple
        accept={["videos"]}
        onChange={f =>
          setFiles(f.length > 0 ? [{ content: f[0], status: "awaiting upload" as const }] : [])
        }
      />

      {!match && videoUrl && (
        <Note style={{ width: 490 }} variant="error" label="Invalid URL">
          The url does not match, make sure it looks like:
          https://www.tiktok.com/@your_cutepet/video/7245827628286954754
        </Note>
      )}

      {match && videoUrl && !loading && !hasValidToken && (
        <Note style={{ width: 490 }} variant="error" label="Missing valid business API token">
          Creator @{match[1]} has no valid business API token. We cannot access the profile through
          the API. Either ask the creator to add a valid token or skip API verification at
          participants page.
        </Note>
      )}

      {loading && <Loader style={{ position: "static" }} />}

      {files.map(file => (
        <Grid
          justifyContent="space-between"
          container
          spacing={4}
          key={file.content.name + file.content.lastModified}
        >
          <Grid item>
            <Chip
              onDelete={() => null}
              deleteIcon={<StatusIcon status={file.status} />}
              label={truncateFileName(file.content.name)}
              icon={<InsertDriveFile />}
            />
          </Grid>

          <Grid item>
            <ButtonGroup>
              <Button
                onClick={() =>
                  setFiles(prev =>
                    prev.filter(
                      f =>
                        f.content.name !== file.content.name &&
                        f.content.lastModified !== file.content.lastModified
                    )
                  )
                }
                endIcon={<Delete style={{ color: palette.primary.red }} />}
                variant="text"
              >
                Delete
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      ))}
    </Popup>
  );
};
