export type ContentRightType =
  | "organic_usage_only"
  | "global_digital_rights"
  | "branded_content_ads"
  | "full_commercial_rights";

export const contentRightPassage: Record<ContentRightType, string> = {
  full_commercial_rights:
    "Full Commercial rights. \n The Client has the right to use the content and Posts created for the Campaign for all types of marketing for {{period}}. \nThe Producer furthermore grants the Company and Client, if applicable, to make minor adjustments to the Ads.",
  branded_content_ads:
    "Ads. \n Ads to all the Posts produced for the Campaign, from the date the content is published, and {{period}} forward. \nThe Producer furthermore grants the Company and Client, if applicable, to make minor adjustments to the Ads.",
  global_digital_rights:
    "Global digital rights. \n The Client has the right to use the content and Posts created for the Campaign for all types of digital marketing for {{period}}. \nThe Producer furthermore grants the Company and Client, if applicable, to make minor adjustments to the Ads.",
  organic_usage_only:
    "Organic usage. \n The Client will use the Posts created for the Campaign, from the date the content is published, \n in their own channels provided that Client refers to the Producer as the content creator and publish it without any paid media for {{period}}."
};
