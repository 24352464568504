import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Campaign_Bool_Exp>;
}>;


export type CampaignsQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, title: string, stub: string, platform?: Types.Platform_Enum | null, list_id?: number | null, project: { __typename?: 'project', id: number, stub: string, name: string, hubspot_deals: Array<{ __typename?: 'hubspot_deal', id: number, hubspot_company_id: string, client: { __typename?: 'clients', name?: string | null, hubspot_company_id: string } }>, campaigns: Array<{ __typename?: 'campaign', id: number, stub: string, title: string }>, project_attachments: Array<{ __typename?: 'project_attachments', id: number, name: string, link: string }> }, content_settings: Array<{ __typename?: 'campaign_content_settings', id: number, type: Types.Content_Preapproval_Type_Enum }> }> };

export type UpdateCampaignUserStateMutationVariables = Types.Exact<{
  campaignUserId: Types.Scalars['Int']['input'];
  state?: Types.InputMaybe<Types.Scalars['campaign_user_state']['input']>;
  appendedLog: Types.Campaign_User_Append_Input;
}>;


export type UpdateCampaignUserStateMutation = { __typename?: 'Mutation', update_campaign_user_by_pk?: { __typename?: 'campaign_user', id: number } | null };

export type UpdateCampaignUserMutationVariables = Types.Exact<{
  _set: Types.Campaign_User_Set_Input;
  campaignUserId: Types.Scalars['Int']['input'];
}>;


export type UpdateCampaignUserMutation = { __typename?: 'Mutation', update_campaign_user_by_pk?: { __typename?: 'campaign_user', id: number } | null };


export const CampaignsDocument = gql`
    query campaigns($where: campaign_bool_exp) {
  campaigns: campaign(where: $where) {
    id
    title
    stub
    platform
    project {
      id
      stub
      name
      hubspot_deals {
        id
        hubspot_company_id
        client {
          name
          hubspot_company_id
        }
      }
      campaigns(where: {archived: {_eq: false}}, order_by: [{title: asc}]) {
        id
        stub
        title
      }
      project_attachments {
        id
        name
        link
      }
    }
    list_id: list
    content_settings: campaign_content_settings {
      id
      type
    }
  }
}
    `;

/**
 * __useCampaignsQuery__
 *
 * To run a query within a React component, call `useCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
      }
export function useCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
        }
export function useCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
        }
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsLazyQueryHookResult = ReturnType<typeof useCampaignsLazyQuery>;
export type CampaignsSuspenseQueryHookResult = ReturnType<typeof useCampaignsSuspenseQuery>;
export type CampaignsQueryResult = Apollo.QueryResult<CampaignsQuery, CampaignsQueryVariables>;
export const UpdateCampaignUserStateDocument = gql`
    mutation updateCampaignUserState($campaignUserId: Int!, $state: campaign_user_state, $appendedLog: campaign_user_append_input!) {
  update_campaign_user_by_pk(
    pk_columns: {id: $campaignUserId}
    _set: {state: $state}
    _append: $appendedLog
  ) {
    id
  }
}
    `;
export type UpdateCampaignUserStateMutationFn = Apollo.MutationFunction<UpdateCampaignUserStateMutation, UpdateCampaignUserStateMutationVariables>;

/**
 * __useUpdateCampaignUserStateMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignUserStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignUserStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignUserStateMutation, { data, loading, error }] = useUpdateCampaignUserStateMutation({
 *   variables: {
 *      campaignUserId: // value for 'campaignUserId'
 *      state: // value for 'state'
 *      appendedLog: // value for 'appendedLog'
 *   },
 * });
 */
export function useUpdateCampaignUserStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignUserStateMutation, UpdateCampaignUserStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignUserStateMutation, UpdateCampaignUserStateMutationVariables>(UpdateCampaignUserStateDocument, options);
      }
export type UpdateCampaignUserStateMutationHookResult = ReturnType<typeof useUpdateCampaignUserStateMutation>;
export type UpdateCampaignUserStateMutationResult = Apollo.MutationResult<UpdateCampaignUserStateMutation>;
export type UpdateCampaignUserStateMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignUserStateMutation, UpdateCampaignUserStateMutationVariables>;
export const UpdateCampaignUserDocument = gql`
    mutation updateCampaignUser($_set: campaign_user_set_input!, $campaignUserId: Int!) {
  update_campaign_user_by_pk(_set: $_set, pk_columns: {id: $campaignUserId}) {
    id
  }
}
    `;
export type UpdateCampaignUserMutationFn = Apollo.MutationFunction<UpdateCampaignUserMutation, UpdateCampaignUserMutationVariables>;

/**
 * __useUpdateCampaignUserMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignUserMutation, { data, loading, error }] = useUpdateCampaignUserMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      campaignUserId: // value for 'campaignUserId'
 *   },
 * });
 */
export function useUpdateCampaignUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignUserMutation, UpdateCampaignUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignUserMutation, UpdateCampaignUserMutationVariables>(UpdateCampaignUserDocument, options);
      }
export type UpdateCampaignUserMutationHookResult = ReturnType<typeof useUpdateCampaignUserMutation>;
export type UpdateCampaignUserMutationResult = Apollo.MutationResult<UpdateCampaignUserMutation>;
export type UpdateCampaignUserMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignUserMutation, UpdateCampaignUserMutationVariables>;