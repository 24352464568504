import { type FC, useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { numberWithCommas, prettifyDate, prettifyNumber } from "@relatable/helpers";
import { Checkbox } from "@relatable/ui/Checkbox";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

import { Popup } from "components/ui/Popup";

import { ArrowDropDown, ArrowDropUp, Info, Settings } from "@mui/icons-material";
import type { FinanceQuery } from "../generated";
import { useUpdateProjectInvoiceMutation } from "./generated";

type Project = FinanceQuery["clients"][number]["hubspot_deals"][number]["project"];
type ProjectRowProps = {
  additionalInvoiceInfo?: string;
  account: Project["account"];
  currency: string;
  estimatedProjectMargin: number;
  name: string;
  project_invoices: Project["project_invoices"];
  projectMargin: number;
  stub: string;
  tableWidth: number;
};

export const ProjectRow: FC<ProjectRowProps> = ({
  additionalInvoiceInfo = "",
  account: project_owner,
  currency,
  estimatedProjectMargin,
  name,
  project_invoices = [],
  projectMargin,
  stub,
  tableWidth
}) => {
  const [open, setOpen] = useState(false);
  const [isInvoiceInfoPopup, setIsInvoiceInfoPopup] = useState(false);

  const [updateProjectInvoice] = useUpdateProjectInvoiceMutation();

  const invoicedProjectInvoices = project_invoices.filter(pi => pi.invoiced);
  const invoicesCount = project_invoices.length;
  const invoicesAmount = project_invoices.reduce((acc, pi) => acc + (pi?.amount || 0), 0);
  const invoicedInvoicesCount = invoicedProjectInvoices.length;
  const invoicedInvoicesAmount = invoicedProjectInvoices.reduce(
    (acc, pi) => acc + (pi?.amount || 0),
    0
  );
  const nextDueInvoice = project_invoices.find(pi => !pi.invoiced);

  const handleInvoicedCheckChange = ({ isChecked, id }: { isChecked: boolean; id: number }) => {
    updateProjectInvoice({
      variables: {
        id,
        _set: { invoiced: isChecked, invoiced_at: isChecked ? new Date().toISOString() : null }
      }
    });
  };

  return (
    <>
      <CollapisbleTableRow open={open}>
        <StyledTableCell width={0.04 * tableWidth}>
          <ExpandProjectIconButton onClick={() => setOpen(!open)}>
            {open ? <ArrowDropUp fontSize="large" /> : <ArrowDropDown fontSize="large" />}
          </ExpandProjectIconButton>
        </StyledTableCell>
        <StyledTableCell className="project-name" width={0.1 * tableWidth}>
          <StyledLink to={`/overview?project=${encodeURIComponent(stub)}`}>{name}</StyledLink>
        </StyledTableCell>
        <StyledTableCell width={0.13 * tableWidth} align="right">
          {numberWithCommas(invoicesAmount)} {currency}
        </StyledTableCell>
        <StyledTableCell
          className={`invoices-status ${
            invoicedInvoicesCount === invoicesCount && invoicesCount !== 0 ? "on-track" : ""
          }`}
          width={0.06 * tableWidth}
          align="center"
        >
          {invoicedInvoicesCount}/{invoicesCount}
        </StyledTableCell>
        <StyledTableCell width={0.15 * tableWidth}>
          {project_owner?.full_name || ""}
        </StyledTableCell>
        <StyledTableCell
          width={0.07 * tableWidth}
          align="right"
          className={`project-margin ${estimatedProjectMargin <= projectMargin ? "on-track" : ""}`}
        >
          {prettifyNumber(projectMargin, { percentages: true })}
        </StyledTableCell>
        <StyledTableCell width={0.13 * tableWidth} align="right">
          {numberWithCommas(invoicedInvoicesAmount)} {currency}
        </StyledTableCell>
        <StyledTableCell width={0.18 * tableWidth}>
          {nextDueInvoice
            ? nextDueInvoice.invoice_by_date || nextDueInvoice.invoice_by_condition
            : "n/a"}
        </StyledTableCell>
        <StyledTableCell width={0.06 * tableWidth}>
          {project_invoices.filter(pi => pi.accounted_at).length}/{project_invoices.length}
        </StyledTableCell>
        <StyledTableCell width={0.02 * tableWidth} padding="none">
          {additionalInvoiceInfo ? (
            <Info
              style={{ cursor: "pointer", color: palette.secondary.blue }}
              onClick={() => setIsInvoiceInfoPopup(true)}
            />
          ) : null}
        </StyledTableCell>
        {/* we want the last row to take up the remaining space */}
        <StyledTableCell width="100%">
          <Tooltip title="Project setup" placement="top">
            <StyledLink to={`/project/${stub}/edit`}>
              <Settings style={{ cursor: "pointer" }} />
            </StyledLink>
          </Tooltip>
        </StyledTableCell>
      </CollapisbleTableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {project_invoices.length > 0 ? (
              <Table>
                <TableBody>
                  {project_invoices.map(invoice => (
                    <TableRow key={invoice.id}>
                      <StyledTableCell width={0.04 * tableWidth} />
                      <StyledTableCell width={0.1 * tableWidth}>{invoice.name}</StyledTableCell>
                      <StyledTableCell width={0.13 * tableWidth} align="right">
                        {numberWithCommas(invoice.amount)} {currency}
                      </StyledTableCell>
                      <StyledTableCell width={0.06 * tableWidth} align="center">
                        <Checkbox
                          style={{ margin: 0 }}
                          label=""
                          checked={Boolean(invoice.invoiced)}
                          onChange={isChecked =>
                            handleInvoicedCheckChange({
                              id: invoice.id,
                              isChecked
                            })
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell width={0.15 * tableWidth} />
                      <StyledTableCell width={0.07 * tableWidth} align="right" />
                      <StyledTableCell width={0.13 * tableWidth} align="right">
                        {invoice.invoiced ? `${numberWithCommas(invoice.amount)} ${currency}` : "-"}
                      </StyledTableCell>
                      <StyledTableCell width={0.18 * tableWidth}>
                        {invoice.invoice_by_date || invoice.invoice_by_condition || "-"}
                      </StyledTableCell>
                      <StyledTableCell width={0.06 * tableWidth} align="center">
                        <Checkbox
                          style={{ margin: 0 }}
                          label={
                            invoice.accounted_at ? (
                              <span style={{ fontSize: 8, textWrap: "nowrap" }}>
                                {prettifyDate(invoice.accounted_at)}
                              </span>
                            ) : (
                              ""
                            )
                          }
                          checked={Boolean(invoice.accounted_at)}
                          onChange={isChecked => {
                            updateProjectInvoice({
                              variables: {
                                id: invoice.id,
                                _set: {
                                  accounted_at: isChecked ? new Date().toISOString() : null
                                }
                              }
                            });
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell width={0.02 * tableWidth} padding="none" />
                      {/* we want the last row to take up the ramining space */}
                      <StyledTableCell width="100%" />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <span style={{ padding: 12, display: "inline-block" }}>
                No invoices yet for this project
              </span>
            )}
          </Collapse>
        </TableCell>
      </TableRow>

      {isInvoiceInfoPopup && (
        <Popup
          onAction={() => setIsInvoiceInfoPopup(false)}
          onClose={() => setIsInvoiceInfoPopup(false)}
          title={`Additional invoice info for ${name}`}
        >
          {additionalInvoiceInfo}
        </Popup>
      )}
    </>
  );
};

const StyledLink = styled(Link)`
  color: ${palette.gray.black};
  &:hover {
    text-decoration: underline;
  }
`;

const ExpandProjectIconButton = styled(IconButton)`
  && {
    height: 26px;
    width: 26px;
  }
`;

const CollapisbleTableRow = styled(TableRow)<{ open: boolean }>`
  background-color: ${({ open }) => (open ? `${palette.tertiary.red}` : "initial")};
`;

const StyledTableCell = styled(TableCell)<{ width: number | string }>`
  ${({ width }) => {
    const widthToRender = typeof width === "string" ? width : `${Math.floor(width)}px`;
    return css`
      min-width: ${widthToRender};
      max-width: ${widthToRender};
    `;
  }};

  &.project-name {
    font-weight: 500;
    font-size: 14px;
  }

  &.invoices-status,
  &.project-margin {
    color: ${palette.primary.red};
    &.on-track {
      color: ${palette.secondary.blue};
    }
  }
`;
