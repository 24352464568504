import { useEffect, useMemo, useState } from "react";

import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useDebounce } from "@relatable/ui/hooks/useDebounce";

import { useSearchListsLazyQuery, useSearchListsQuery } from "modules/curation/generated";

export const ListSelector: React.FC<{
  listId: number | null;
  label?: string;
  onChange: (listId: number | null) => void;
  platform?: Platform_Enum;
  inputStyle?: React.CSSProperties;
}> = ({ listId, label, onChange, platform, inputStyle }) => {
  const snackbar = useSnackbar();
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | null>(null);
  const debouncedQuery = useDebounce(query, 700);

  const {
    data: { lists: selectedListDetails = [] } = {}
  } = useSearchListsQuery({
    variables: { where: { id: { _eq: Number(listId) } } },
    skip: !listId
  });

  const [
    searchLists,
    {
      data: { lists = [] } = {},
      loading
    }
  ] = useSearchListsLazyQuery();
  useEffect(() => {
    const nameLike = `%${(debouncedQuery ?? "").replace(/\s+/g, "%")}%`;
    searchLists({
      variables: {
        where: { name: { _ilike: nameLike }, platform: { _eq: platform } }
      }
    });
  }, [searchLists, listId, platform, debouncedQuery]);

  // memo is required to prevent reset of internal state in <Autocomplete />
  const listOptions = useMemo(() => {
    let list = lists ?? [];
    if (selectedListDetails?.length > 0 && !list.find(l => l.id === selectedListDetails[0].id)) {
      list = [...list, selectedListDetails[0]];
    }

    return list.map(l => ({
      id: l.id,
      label: `${l.name} (${l.users_in_lists_aggregate.aggregate?.count})`
    }));
  }, [lists, selectedListDetails]);

  const value = listId ? (listOptions.find(l => l.id === listId) ?? null) : null;

  return (
    <Autocomplete
      disablePortal
      options={listOptions}
      fullWidth
      onChange={(e, v) => onChange(v?.id ?? null)}
      value={value}
      inputValue={(open ? query : value?.label) ?? ""}
      onInputChange={(e, v, reason) => {
        if (reason === "input") setQuery(v);
      }}
      onOpen={() => {
        setOpen(true);
        snackbar.info("Can't find your list here? Make sure they match the same platform!");
      }}
      onClose={() => setOpen(false)}
      loading={loading}
      loadingText="Loading..."
      isOptionEqualToValue={(option, v) => option.id === v.id}
      autoSelect
      size="small"
      /* Built-in filtering needs to be disabled at lazy loaded requests */
      filterOptions={x => x}
      renderInput={params => (
        <TextField
          {...params}
          label={label ?? "Select list"}
          slotProps={{
            input: {
              ...params.InputProps,
              style: inputStyle,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }
          }}
        />
      )}
    />
  );
};
